<template>
  <div class="ft-car-placeholder">
    <div class="ft-slide">
      <div class="landscape"></div>
    </div>
    <!-- <div class="vlt-desc1"></div>
    <div class="vlt-desc-main">
      <div v-for="(n, index) in 3 " :key="index">
        <div class="vlt-desc2"></div>
      </div>
    </div>

    <div class="vlt-desc3"></div>
    <div class="vlt-desc4"></div> -->
    <div class="vlt-pagination">
      <div class="brick"></div>
      <div class="brick"></div>
      <div class="brick"></div>
      <div class="brick"></div>
      <div class="brick"></div>
      <div class="brick"></div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "FeaturedPlaceholder"
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_mediaQueries.scss";

.ft-car-placeholder {
  overflow-x: hidden;
  padding-top: 30px;
  .ft-slide {
    // width: 70%;
    // width: 785px;
    width: 45%;
    .landscape{
      width:100%;
      height:0;
      padding:28.125% 0;
    }
    margin: 0 auto;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from($clr-dark-grad-2),
      to($clr-dark-grad-3)
    );
    background: transparent
      linear-gradient(29deg, $clr-bg-gray-light-8 0%, $clr-bg-gray-dark 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 5px 5px $clr-color-black-3;
    // background: linear-gradient(to right, $clr-dark-grad-2, $clr-dark-grad-3);
    border-radius: 5px;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: transparent
        linear-gradient(29deg, $clr-bg-gray-light-8 0%, $clr-bg-gray-dark 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 5px $clr-color-black-3;
      // background: linear-gradient(to right, $clr-dark-grad-2, $clr-dark-grad-3);
      top: 0;
      border-radius: 5px;
    }

    &::before {
      right: 110%;
    }
    &::after {
      left: 110%;
    }
  }


  .vlt-pagination{
    margin:15px 0px;
    text-align:center;
      .brick{
        display: inline-block;
        width: 50px;
        height: 6px;
        border-radius:2px;
        margin:0px 10px;
        background: $clr-bg-gray-dark-2 0% 0% no-repeat padding-box;
      }
  }
}


@include breakpoint(max1280) {
  .ft-car-placeholder .ft-slide {
      width:60%;

      &::after{
        left:105%;
      }
      &::before{
        right:105%;
      }
    }
  
}

@include breakpoint(max576) {
  .vlt-pagination{
    display:none;
  }
  .ft-car-placeholder .ft-slide {
      width:90%;

      &::after{
        left:103%;
      }
      &::before{
        right:103%;
      }
    }
 
}




</style>